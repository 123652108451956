import React from 'react';

import { AppStateContext } from 'src/contexts/app-state-provider.context';
import Layout from '../components/layout';
import { TranslatedBlock } from '../components/language';

export default class TermsAndConditions extends React.Component {
  static contextType = AppStateContext;
  componentDidMount() {
    this.context.setPage('terms-and-conditions');
  }

  render() {
    return (
      <Layout
        title={{
          english: 'Terms and Conditions',
          french: "Conditions d'utilisation",
        }}
        showTab={true}
      >
        <TranslatedBlock language="english">
          <h2>Terms and Conditions</h2>
          <p>EFFECTIVE AS OF July 16, 2010</p>
          <p>TERMS OF USE</p>
          <p>
            PLEASE READ AND REVIEW CAREFULLY THE FOLLOWING TERMS OF USE BEFORE USING THIS WEB SITE. This website was
            developed as a service provided by Hoffmann-La Roche Limited (“Roche”). By using this website, you agree to
            these Terms of Use. If you do not agree, please exit and disregard the information contained herein.
          </p>
          <p>
            <u>Users</u>
          </p>
          <p>
            The material on this website is intended only for general information and education purposes, and is only
            for residents of Canada.
          </p>
          <p>
            <u>Modification of Terms or Website</u>
          </p>
          <p>
            Roche reserves the right to change these terms or any part of this website from time to time, as it deems to
            be necessary. You may view the current applicable version of these Terms of Use at any time through a link
            at the bottom of each page within this website.
          </p>
          <p>
            <u>Medical Information</u>
          </p>
          <p>
            This website includes product and medical information intended for educational use only. The information
            provided on this website is not intended to be comprehensive, nor is it a substitute for professional
            medical advice and judgment. USERS MUST CONSULT A SUITABLY QUALIFIED HEALTHCARE PROFESSIONAL ON ANY PROBLEM
            OR MATTER WHICH IS COVERED BY ANY INFORMATION ON THIS WEBSITE BEFORE TAKING ANY DECISION OR ACTION.
          </p>
          <p>
            <u>Disclaimers</u>
          </p>
          <p>
            Roche has made an effort to include accurate and up-to-date information on this website. However, since
            medical science is always in flux, the information on this website may not be up-to-date, and is therefore
            provided “as is”. Roche makes no warranties or representations of any kind, express or implied, about any
            content on this website. Furthermore, Roche does not warrant that the functions contained on this website
            will be uninterrupted or error-free, or that this website or the server that makes it available are free of
            viruses or other harmful components. Some jurisdictions do not allow the exclusion of implied warranties, so
            the above exclusion may not apply to you.
          </p>
          <p>
            All users agree that all access and use of this website and its content is at their own risk. Neither Roche,
            nor its affiliated companies, nor any other party involved in creating, producing or delivering this website
            shall be liable in any manner whatsoever for any direct, incidental, consequential, indirect or punitive
            damages arising out of (a) a user’s access, use or inability to use this website and (b) any errors or
            omissions in the website’s content.
          </p>
          <p>
            <u>Third Party Content and Links to Other Websites</u>
          </p>
          <p>
            This website may contain third party owned content (e.g. articles, data feeds or abstracts) and contains
            links to other websites maintained by third parties over whom Roche has no control. Such content and links
            are provided merely as a convenience to users of this website. Roche does not endorse and assumes no
            responsibility for the content of such websites and makes no representations as to the accuracy or
            completeness of any information contained on such sites. In particular, Roche does not accept any liability
            arising out of any allegation that any third party owned content (whether published on this, or any other,
            website) infringes the intellectual property rights of any person or any liability arising out of any
            information or opinion contained on such third party website or content.
          </p>
          <p>
            <u>Intellectual Property</u>
          </p>
          <p>
            All images and information contained on this website are copyrighted and otherwise proprietary. Unless
            otherwise expressly noted, nothing on this website may be copied or used except as provided in these Terms
            of Use or with the prior written consent of Roche.
          </p>
          <p>
            Roche grants you permission to print individual pages from this website, unless otherwise expressly noted,
            for your personal, non-commercial use. No other permission is granted to you to print, copy, reproduce,
            distribute, license, transfer, sell, transmit, upload, download, store, display in public, alter, modify or
            create derivative works of these materials. This grant of permission is not a transfer of title.
          </p>
          <p>
            Unless otherwise indicated, all logos, designs, and marks on this website are trademarks or service marks
            owned or used under license by Roche. All product names printed in capitals or otherwise appropriately
            marked on this website are trademarks of their respective owners.
          </p>
          <p>
            <u>Privacy</u>
          </p>
          <p>
            Please refer to Roche’s Privacy Policy for information on how Roche protects personal information about you.
          </p>
          <p>
            <u>Termination of Access</u>
          </p>
          <p>
            Roche reserves the right to suspend or terminate your access to all or part of this website, at any time,
            without notice to you, if it believes, in its sole judgment, that you have breached or may breach any term
            or condition of this agreement, or for its convenience.
          </p>
        </TranslatedBlock>
        <TranslatedBlock language="french">
          <h2>Conditions d'utilisation</h2>
          <p>EN VIGUEUR LE 16 juillet 2010</p>
          <p>CONDITIONS D'UTILISATION</p>
          <p>
            VEUILLEZ LIRE ATTENTIVEMENT LES CONDITIONS D'UTILISATION CI-DESSOUS AVANT D’UTILISER CE SITE WEB. Ce site
            Web a été élaboré à titre de service offert par Hoffmann-La Roche Limited/Limitée (« Roche »). En utilisant
            ce site Web, vous convenez de respecter ces conditions d'utilisation. Si vous ne les acceptez pas, veuillez
            quitter le site et ne pas tenir compte des renseignements qu’il contient.
          </p>
          <p>
            <u>Utilisateurs</u>
          </p>
          <p>
            La documentation de ce site Web ne vise qu’à présenter du contenu informatif et éducatif à des résidents
            canadiens.
          </p>
          <p>
            <u>Modifications des conditions du site Web</u>
          </p>
          <p>
            Roche se réserve le droit de modifier de temps à autre, au besoin, les conditions d'utilisation de ce site
            Web et de n’importe quelle partie de son contenu. Vous pouvez accéder à la version actuellement en vigueur
            de ces conditions d'utilisation au moyen d’un lien au bas de chaque page de ce site Web.
          </p>
          <p>
            <u>Information médicale</u>
          </p>
          <p>
            Ce site Web comprend des renseignements sur des produits et des renseignements médicaux de nature
            strictement éducative. Les renseignements fournis dans ce site Web ne prétendent pas être exhaustifs et ne
            remplacent pas les conseils ni le jugement d’un médecin. LES UTILISATEURS DOIVENT CONSULTER UN PROFESSIONNEL
            DE LA SANTÉ QUALIFIÉ AU SUJET DES PROBLÈMES OU DES QUESTIONS ABORDÉS SUR CE SITE WEB AVANT DE PRENDRE
            QUELQUE DÉCISION OU MESURE QUE CE SOIT.
          </p>
          <p>
            <u>Avis de rejet de responsabilité</u>
          </p>
          <p>
            Roche s’est efforcée d’inclure dans son site Web des renseignements exacts et à jour. Cependant, la science
            médicale étant en constante évolution, les renseignements contenus dans ce site Web pourraient ne pas être à
            jour, et sont par conséquent fournis « en l’état ». Roche n’offre aucune garantie ou représentation,
            expressément ou implicitement, par rapport au contenu de ce site Web. En outre, Roche ne garantit pas que
            les fonctions contenues dans ce site soient fournies de manière ininterrompue et sans erreur, ni que ce site
            Web ou le serveur qui l’héberge sont exempts de virus ou autres composantes nuisibles. Certaines
            juridictions n’autorisent pas l’exclusion de garanties implicites, si bien que les exclusions ci-dessus
            peuvent ne pas s’appliquer dans votre cas.
          </p>
          <p>
            Les utilisateurs conviennent qu’ils accèdent au présent site et l’utilisent à leurs propres risques. Roche,
            ses sociétés affiliées, et toute autre partie ayant participé à la création, à la production ou à la mise en
            œuvre de ce site Web ne peuvent être tenus responsables de quelque façon que ce soit de dommages directs,
            accessoires, indirects ou punitifs découlant de : a) l’accès, l’utilisation de ce site Web ou l’incapacité à
            utiliser celui-ci et, b) de toute erreur ou omission présentes dans son contenu.
          </p>
          <p>
            <u>Contenu de tiers et liens vers d’autres sites Web</u>
          </p>
          <p>
            Ce site Web peut contenir des données (p. ex. articles, flux de données ou résumés) appartenant à des tiers,
            et contient des liens vers d’autres sites Web exploités par des tiers sur lesquels Roche n’a aucun contrôle.
            Ce contenu et ces liens vers les pages d’autres sites ne sont fournis qu’à titre informatif pour les
            visiteurs. Roche n’assume aucune responsabilité quant au contenu de ces sites Web, pas plus qu’elle ne fait
            de déclaration quant à l’exactitude et l’exhaustivité de l’information contenue dans de tels sites. En
            particulier, Roche décline toute responsabilité découlant de toute allégation selon laquelle tout contenu
            appartenant à des tiers (qu’ils aient été publiés sur ce site Web ou sur tout autre site Web) viole les
            droits de propriété intellectuelle de toute personne, et toute responsabilité découlant de tout
            renseignement ou opinion contenus dans un tel site Web ou contenu d’un tiers.
          </p>
          <p>
            <u>Propriété intellectuelle</u>
          </p>
          <p>
            Toutes les images et toute l’information contenues dans ce site Web sont protégées par des droits d’auteur
            et autrement détenues en propriété exclusive. À moins d’indication contraire expresse, aucun élément de ce
            site Web ne peut être copié ni utilisé, sauf tel qu’il est stipulé dans les conditions d'utilisation ou avec
            le consentement écrit préalable de Roche.
          </p>
          <p>
            Roche vous accorde l’autorisation d’imprimer des pages de ce site Web, sauf indication contraire expresse,
            pour votre usage personnel et non commercial. Aucune autre autorisation ne vous est accordée d’imprimer, de
            copier, de reproduire, de diffuser, de céder, de vendre, de transmettre, de télécharger (vers l’amont ou
            l’aval), de stocker, d’afficher en public, de modifier cette information, ni d’octroyer des licences
            relativement à celle-ci ou de créer des œuvres dérivées à partir de celle-ci. L’octroi de cette autorisation
            ne constitue pas un transfert de titre.
          </p>
          <p>
            À moins d’indication contraire, tous les logos, dessins et marques de commerce figurant dans ce site Web
            sont des marques de commerce détenues ou utilisées sous licence par Roche. Tous les noms de produits qui
            sont en majuscules ou autrement identifiés de façon appropriée dans ce site Web sont des marques de commerce
            de leur détenteur respectif.
          </p>
          <p>
            <u>Confidentialité</u>
          </p>
          <p>
            Veuillez consulter l’énoncé de confidentialité figurant sur ce site Web pour savoir de quelle manière Roche
            protège les renseignements personnels vous concernant.
          </p>
          <p>
            <u>Résiliation de l’accès</u>
          </p>
          <p>
            Roche se réserve le droit de suspendre ou de résilier votre accès à une partie ou à la totalité de ce site
            Web, en tout temps et sans vous donner de préavis, si elle estime, à son entière discrétion, que vous avez
            violé ou pouvez violer l’une quelconque des conditions d'utilisation, ou par commodité.
          </p>
        </TranslatedBlock>
      </Layout>
    );
  }
}
